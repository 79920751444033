import spotiiAuth from '@NymCard-Payments/auth';
import config from 'utils/config';

const authApi = spotiiAuth.merchant({
  oauth: {
    client_id: config('oauth_client_id'),
    client_secret: config('oauth_client_secret'),
    authorizationBasic: config('oauth_authorization_basic'),
    baseUrl: config('oauth_base_url'),
  },
  verification: {
    baseUrl: config('verification_base_url'),
  },
  profile: {
    baseUrl: config('profile_base_url'),
  },
  registration: {
    baseUrl: config('registration_base_url'),
  },
  recovery: {
    baseUrl: config('recovery_base_url'),
  },
});

export default authApi;
