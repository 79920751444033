/* eslint-disable react/display-name */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from 'components/Paper';
import { useDispatch, useSelector } from 'react-redux';
import b from 'b_';
import moment from 'moment';
import Button from 'spotii-ui/Button';
import Currency from 'spotii-ui/Currency';
import message from 'spotii-ui/Message';
import { DateRangePicker } from 'components/DatePicker';
import TableView from 'components/TableView/TableView';
import Pagination from 'components/Pagination';
import OrderStatus from 'components/OrderStatus';
import shortenUuid from 'utils/shortenUuid';
import { fetchReport, changeDates, changePage, reset } from 'reducers/reportOrders';
import { downloadOrdersReport } from 'api';
import { PLAN_SLUGS } from 'utils/constants';

const LINES_PER_PAGE = 10;

//
// Components
//
const Orders = ({ appState }) => {
  const cls = b.with('reports');
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    isLoading,
    isResolved,
    list,
    fromDate: stateFromDate,
    toDate: stateToDate,
    pageCount,
    currentPage,
  } = useSelector(state => state.reportOrders);
  const currLang = useSelector(state => state.language.language);

  // dates should be a MomentJS instance
  const initialStartDate = stateFromDate ? moment(stateFromDate) : null;
  const initialEndDate = stateToDate ? moment(stateToDate) : null;

  const [dateFocus, setDateFocus] = useState(null);
  const [fromDate, setFromDate] = useState(initialStartDate);
  const [toDate, setToDate] = useState(initialEndDate);
  const [isXLsxLoading, setisXLsxLoading] = useState(false);

  const onPageChange = p => dispatch(changePage(p.selected + 1));
  const handleDownload = async event => {
    const { target } = event;
    event.preventDefault();
    target.disabled = true;
    setisXLsxLoading(true);

    try {
      const fromReportDate = fromDate && fromDate.format('YYYY-MM-DD');
      const toReportDate = toDate && toDate.format('YYYY-MM-DD');
      await downloadOrdersReport(fromReportDate, toReportDate);
      target.disabled = false;
      setisXLsxLoading(false);
    } catch (e) {
      console.warn(e);
      message.error('Oops! Something went wrong.');
      target.disabled = false;
      setisXLsxLoading(false);
    }
  };

  const page = Math.max(currentPage, 1) - 1;
  const fetchParams = {
    fromDate: stateFromDate,
    toDate: stateToDate,
    limit: LINES_PER_PAGE,
    page: currentPage,
  };

  // Reset reducer on page view
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(reset()), []);

  // Fetch orders on first render and if one of the listed parameters changed.
  useEffect(() => {
    dispatch(fetchReport(fetchParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateFromDate, stateToDate, currentPage]);

  // Columns render options
  const columns = [
    {
      title: <div className={`column-title-${currLang}`}>{t('date')}</div>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: value => (
        <div className={`line-height column-render-${currLang}`}>
          {moment.parseZone(value).format('YYYY-MM-DD')}
        </div>
      ),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('status')}</div>,
      dataIndex: 'status',
      key: 'status',
      render: value => (
        <div className={`column-title-${currLang}`}>
          <OrderStatus status={value} badge />
        </div>
      ),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('ref')}</div>,
      dataIndex: 'reference',
      key: 'reference',
      render: (value, row) => (
        <div className={`line-height column-render-${currLang}`}>
          {row.displayReference || value}
        </div>
      ),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('spotiiID')}</div>,
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      render: value => (
        <div className={`line-height column-render-${currLang}`}>{value.toUpperCase()}</div>
      ),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('total')}</div>,
      dataIndex: 'total',
      key: 'total',
      render: (value, row) => (
        <div className={`line-height column-render-${currLang}`}>
          <Currency currency={row.currency} amount={value} />
        </div>
      ),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('refunded')}</div>,
      dataIndex: 'totalRefund',
      key: 'totalRefund',
      render: (value, row) => {
        return value ? (
          <div className={`line-height column-render-${currLang}`}>
            <Currency currency={row.currency} amount={value} />
          </div>
        ) : (
          <div className={`column-status-${currLang}`}>-</div>
        );
      },
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('type')}</div>,
      dataIndex: 'planId',
      key: 'planId',
      render: value => {
        let name = '';
        if (value) {
          const { planDictionary } = appState;
          const plan = planDictionary.find(pd => pd.plan_id === value);
          if (plan.slug === PLAN_SLUGS.PAY_NOW) {
            name = t('payNowType');
          } else {
            name = t('instalmentsType');
          }
        }

        return (
          <div className={`line-height column-render-${currLang}`}>
            <div>{name}</div>
          </div>
        );
      },
    },
  ];

  const columnsExtended = columns.slice();
  if (appState.allowNoCallBackUrls) {
    columnsExtended.push({
      title: <div className={`column-title-${currLang}`}>{t('advisor')}</div>,
      dataIndex: 'employeeName',
      key: 'employeeName',
      render: value => (
        <div className={`line-height column-render-${currLang}`}>
          <span>{value}</span>
        </div>
      ),
    });
  }

  return (
    <Paper mobileFull className={cls({ type: 'orders' })}>
      <div className={cls('filters')}>
        <div className={cls('filters-datepicker')}>
          <DateRangePicker
            // Allow to select only one date
            t={t}
            currLang={currLang}
            minimumNights={0}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel
            startDateId="startDate"
            endDateId="endDate"
            startDate={fromDate}
            endDate={toDate}
            onDatesChange={({ startDate, endDate }) => {
              setFromDate(startDate);
              setToDate(endDate);
            }}
            focusedInput={dateFocus}
            onFocusChange={focusedInput => {
              setDateFocus(focusedInput);
            }}
            customArrowIcon="–"
            // Allow to select any date
            isOutsideRange={day => day.isAfter(Date.now())}
            onClose={({ startDate, endDate }) => {
              dispatch(
                changeDates({
                  fromDate: startDate ? startDate.format('YYYY-MM-DD') : null,
                  toDate: endDate ? endDate.format('YYYY-MM-DD') : null,
                }),
              );
            }}
          />
        </div>
        <div className={cls('filters-download')}>
          <Button
            type="link"
            icon="download"
            onClick={handleDownload}
            disabled={isXLsxLoading}
            loading={isXLsxLoading}
          >
            {t('downloadAsXlsx')}
          </Button>
        </div>
      </div>
      <TableView columns={columnsExtended} data={list} loading={isLoading} rowKey="id" />
      {isResolved && list.length ? (
        <div className={cls('pagination')}>
          <Pagination current={page} count={pageCount} onChange={onPageChange} />
        </div>
      ) : null}
    </Paper>
  );
};
Orders.propTypes = {
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
};
export default Orders;
