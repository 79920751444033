/* eslint-disable import/prefer-default-export */
export const getTokenFromUrl = str => {
  if (!str) {
    return str;
  }
  const url = new URL(str);
  const token = new URLSearchParams(url.search).get('token');
  return token;
};

export const persistFlag = (key, value) => {
  if (window.localStorage && window.localStorage.setItem) {
    window.localStorage.setItem(`spotii:${key}`, value);
    return true;
  }
  return false;
};

export const removeFromLocalStorage = (key) => {
  if (window.localStorage && window.localStorage.removeItem) {
    window.localStorage.removeItem(`spotii:${key}`);
    return true;
  }
  return false;
};

export const getFlag = key => {
  const val = window.localStorage.getItem(`spotii:${key}`);
  if (val === 'true') return true;
  if (val === 'false') return false;
  throw Error();
};
